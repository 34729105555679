import React, { FunctionComponent } from 'react';
import { GroupCard } from './ProductGroupCard.styled';
import ProductGroupItem from './ProductGroupItem';
import { IProductGroup } from 'model/interfaces';

interface Props {
  group: IProductGroup;
  onClick?: () => void;
  selected?: boolean;
  hidden?: boolean;
  editable?: boolean;
}

const ProductGroupCard: FunctionComponent<Props> = ({
  group,
  onClick,
  selected,
  editable,
  ...props
}) => {
  const soldOut = group.soldout;
  const lastPlaces = group.quota <= 5;
  return (
    <GroupCard
      {...props}
      onClick={!soldOut ? onClick : () => {}}
      selected={selected}
      disabled={soldOut}
      selectedClickable
      tabIndex={0}
    >
      <ProductGroupItem
        group={group}
        selected={selected}
        soldOut={soldOut}
        lastPlaces={lastPlaces}
        editable={editable}
      />
    </GroupCard>
  );
};

export default ProductGroupCard;
