import styled, { css } from 'styled-components';
import { Card } from 'common/styles/Cards.styled';

interface StyleProps {
  selected?: boolean;
  hidden?: boolean;
  disabled?: boolean;
}

export const GroupCard = styled(Card)`
  min-height: 117px;

  ${({ hidden }: StyleProps) =>
    hidden &&
    css`
      display: none;
    `}
  
  ${({disabled}) =>
    disabled &&
    css`
      background-color: var(--dh-white1);
    `
  }

  /* Tablet & Desktop */
  @media screen and (min-width: 768px) {
    width: 260px;
  }
`;
