import React, { FunctionComponent, useEffect, useState } from 'react';
import { addMinutes, format, parseISO } from 'date-fns';
import ProductGroupCard from 'components/ProductGroup/ProductGroupCard';
import {
  Container,
  GridHead,
  CardSkeleton,
  Grid
} from './ProductGroupList.styled';
import Skeleton from 'react-loading-skeleton';
import useGroup from 'hooks/useGroup';
import { IProduct, IProductGroup } from 'model/interfaces';
import { H2 } from 'common/styles/Headings.styled';
import { useTranslation } from 'next-i18next';
import useDateLocale from 'hooks/useDateLocale';
import useCountryCode from 'hooks/useCountryCode';
import useProduct from 'hooks/useProduct';
import { EBusinessUnit } from 'model/enums';

interface Props {
  product: IProduct;
  groups?: IProductGroup[] | null;
}

interface MonthGroupList {
  month: string;
  groups: IProductGroup[];
}

const ProductGroupList: FunctionComponent<Props> = ({ product, groups }) => {
  const [list, setList] = useState<MonthGroupList[] | undefined>();
  const { t } = useTranslation('main');
  const { isBusinessUnit } = useProduct(product);
  const isOnDemand = isBusinessUnit(EBusinessUnit['on_demand']);
  const [country] = useCountryCode();
  const dateLocale = useDateLocale(country);
  const { group: selectedGroup, setGroup, unsetGroup, fixed } = useGroup(
    product
  );

  const getList = (groups: IProductGroup[]) => {
    let innerList = groups.slice();

    if (selectedGroup) {
      const selectedExists = groups.find(({ id }) => id === selectedGroup.id);
      if (!selectedExists) {
        innerList.push(selectedGroup);
      }
    }
    
    const dummyDate = '2011-06-26T00:00:00Z';

    return innerList
      .slice()
      .sort(
        (a, b) =>
          addMinutes(
            parseISO(a.startsAt || dummyDate),
            parseISO(a.startsAt || dummyDate).getTimezoneOffset()
          ).getTime() -
          addMinutes(
            parseISO(b.startsAt || dummyDate),
            parseISO(b.startsAt || dummyDate).getTimezoneOffset()
          ).getTime()
      )
      .reduce((list: MonthGroupList[], item) => {
        const month = format(
          addMinutes(
            parseISO(item.startsAt || dummyDate),
            parseISO(item.startsAt || dummyDate).getTimezoneOffset()
          ),
          'MMMM',
          {
            locale: dateLocale
          }
        );
        const monthList = list.find(i => i.month === month);

        if (monthList) {
          monthList.groups.push(item);
        } else {
          list.push({
            month: month,
            groups: [item]
          });
        }
        return list;
      }, []);
  };

  const handleClick = (group: IProductGroup) => {
    // avoid unselect on recurring flow
    if (!product.onlyConfiguration
      || (product.onlyConfiguration && !isSelected(group))) {
      isSelected(group) ? !fixed && unsetGroup() : setGroup(group);
    }
  };

  const isSelected = (group: IProductGroup) => {
    return !!selectedGroup && selectedGroup.id === group.id;
  };

  const isHidden = (group: IProductGroup) => {
    return !!selectedGroup && !isSelected(group);
  };

  useEffect(() => {
    if (groups) setList(getList(groups));
  }, [groups, selectedGroup]);

  useEffect(() => {
    if (!isOnDemand || !groups?.length) return;
    
    const group = groups[0];

    setGroup(group);
  }, [groups, isOnDemand]);

  if (isOnDemand) return null;

  return (
    <Container>
      {list && selectedGroup && <H2>{t('group.start')}</H2>}
      {(list &&
        list.map(({ month, groups }) => (
          <React.Fragment key={month}>
            {!selectedGroup && <GridHead>{t('group.begin')} {month}</GridHead>}
            <Grid mobileHorizontalScroll>
              {groups.map(group => (
                <ProductGroupCard
                  key={group.id}
                  group={group}
                  selected={isSelected(group)}
                  hidden={isHidden(group)}
                  onClick={() => handleClick(group)}
                  editable={!fixed && !product.onlyConfiguration}
                />
              ))}
            </Grid>
          </React.Fragment>
        ))) || (
        <Grid skeleton mobileHorizontalScroll>
          <GridHead>
            <Skeleton width={250} />
          </GridHead>
          <CardSkeleton count={3} />
        </Grid>
      )}
    </Container>
  );
};

export default ProductGroupList;
