import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { CardGrid } from 'common/styles/Cards.styled';
import { H2 } from 'common/styles/Headings.styled';

interface StyleProps {
  skeleton?: boolean;
}

export const Container = styled.section`
  margin-top: 40px;
`;

export const Grid = styled(CardGrid)`
  ${({ skeleton }: StyleProps) =>
    skeleton &&
    css`
      & > span {
        /* Tablet & Desktop */
        @media screen and (min-width: 768px) {
          grid-auto-columns: 260px;
          grid-template-columns: repeat(auto-fill, 260px);
        }
      }
    `}
  /* Tablet & Desktop */
  @media screen and (min-width: 768px) {
    grid-auto-columns: 260px;
    grid-template-columns: repeat(auto-fill, 260px);
  }
`;

export const GridHead = styled(H2)`
  &:first-child {
    margin-top: 0;
  }
`;

export const CardSkeleton = styled(Skeleton)`
  scroll-snap-align: center;
  height: 117px;
`;
